$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #E4032E;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") center / contain no-repeat;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/brb-logo-mono-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-left: -5rem !important;
                height: 3.5rem !important;
                width: 21.5rem !important;
            }

            @media (max-width: 991px) {
               .cloud-logo {
                    background: $logoBackgroundTablet;
                    margin-left: 0.5em!important;
                    position: absolute;
                    left: 0;
                    width: 208px !important;
                }
            }
            @media (max-width: 557px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                    width: 112px !important;
                    height: 50px;
                    margin-top: -8px;
                 }
             }
        }
    }
}

.section-imprint.loggedout ul {
	list-style-type: none!important;
	margin: 0!important;
}

.made-with-love {
    .poweredby-logo {
        height: 1em;
        vertical-align: middle;
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@import '../lib/colors';
@import '../lib/bootstrap/scss/bootstrap';

body {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    p{
        margin: 0 0 10px 0;
    }
    ul {
        list-style-type: none;
        padding:0;
        margin:0;
    }
    header {
        background: $colorWhite;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
        a {
            color: $secondaryColor !important;
            font-size: 1rem;
            line-height: 1rem;
            padding: 1rem !important;
            text-decoration: none;
        }
        #title {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: 400;
            display: inline-block;
            padding-left: 0 !important;
            padding-top: .75rem !important;
            padding-bottom: .75rem !important;
            &:hover{
                text-decoration: none;
            }
            .cloud-logo {
                display: inline-block;
                width: 173px;
                height: 2rem;
                margin-top: -.25rem;
                margin-bottom: -.75rem;
                margin-right: .5rem;
                background: $logoBackground;
                background-size: contain;
            }
        }
        .nav-item{
            margin: 0 !important;
            min-width: 4rem;
            text-align: center;
            .nav-link {
                transition: background-color .3s ease-in-out;
                &:hover{
                    background-color: $accentColor;
                }
            }
        }
        .nav-item:last-of-type{
            padding-right: 0;
        }
        @include media-breakpoint-down(md) {
            text-align: center;
            #title, ul.nav{
                display: block;
            }
            ul.nav .nav-item{
                float: none;
                display: inline-block;
            }
        }
    }
    main{
		min-height: calc(100vh - 228px);
		#frontpage-notification-top {
			.vcenter {
				display: flex;
				align-items: center;
			}
			.alert{
				padding:12px 16px;
				color:white;
				background-color:#9e292b;
				border-color:transparent;
				margin:0;
			}
			a {
				color: white;
				&:hover {
					text-decoration: none!important;
				}
			}
			h5 {
				span {
					border-bottom: solid 1px white;
				}
			}
			@media (max-width: 1024px) {
				h5 {
					margin-left: 10px;
				}
			}
		}
        .illustration{
            min-width: 150px;
            width: 25vw;
            max-width: 300px;
            shape-outside: circle(50%);
        }
        li{
            margin-top: 5px;
            &:before{
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\f105";
                margin-right: 5px;
            }
        }
    }

    #loginarea{
			.btn{
					word-wrap: break-word;
					white-space: normal;
			}
			input {
					font-size: 1rem;
					padding: .5rem;
			}
			.login-providers {
					display: none;
			}
			.chosen-results {
					li {
							line-height: 1.2;
					}
			}
			.email-login-section {
					display: none;
			}
			.ldap-login-section {
					display: none;
			}
			.system-display {
					display: none;
			}
	}

    aside{
        .card-block {
            padding: 1rem 1.25rem;
        }
        .card-title{
            break-word: break-all;
        }
        #blogfeed{
            .list-group-item{
                padding: .75rem .25rem !important;
            }
        }
    }
    footer:not(.small-footer){
        background-color: #555;
        color: #fff;
        padding:2rem 2rem 0;
        a{
            color: #fff;
            &:hover{
                color:#fff!important;
            }
        }
        .col-md-4{
            margin-bottom: 2rem;
        }
    }
    footer.small-footer{
        position: relative;
    }
}

.partner-img {
	img {
		padding: 10px 20px;
	}
}
